import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const Result = styled.div`
	width: 100%;
	background-color: white;
	z-index: 1000;
	padding: 40px;
	margin-top: 25px;
	box-shadow: ${({ theme }) => theme.shadows.searchElements};
	border-radius: ${({ theme }) => theme.radii.xl};
`;

const Heading = styled.h1`
	font-size: ${({ theme }) => theme.fontSizes.ml};
	color: ${({ theme }) => theme.colors.typography};
	font-family: ${({ theme }) => theme.fonts.bold};
`;

const Description = styled.div`
	font-size: ${({ theme }) => theme.fontSizes.m};
	color: ${({ theme }) => theme.colors.typography};
	font-family: ${({ theme }) => theme.fonts.regular};
	margin-top: 24px;
`;

const SearchResult = ({ errorCode }) => {

	return ( 
		<Wrapper>
			{ errorCode.toLowerCase() === "acic-516099-3" && (
				<Result>
				<Heading>ACIC-516099-3</Heading>
				<Description>The tilt function of the knob for operating the infotainment system is faulty</Description>
			</Result>
			) }
		</Wrapper>
	 );
}
 
export default SearchResult;